import {Steps, Button} from "antd";
import React from "react";
import {Survey,YesOrNoRadios, SurveyTextarea} from "./Survey"
import {AP_ANNUAL_SURVEY_EVENT_TYPE_ID} from "../helpers/misc"
import {AP_SURVEY_QUESTIONS_ANNUAL_CONTROL} from "../helpers/misc_survey"

export class SurveyAnnualControl extends Survey {
  state = {
    loading_page: false,
    current: 0,
    bigQuestions: [28],
    questions: AP_SURVEY_QUESTIONS_ANNUAL_CONTROL,
    constants: {
      AP_ANNUAL_SURVEY_EVENT_TYPE_ID
    }
  }

  async componentDidMount() {

    void this.getDataForForm(this.state.constants.AP_ANNUAL_SURVEY_EVENT_TYPE_ID)
  }

render() {
  return (<>
      <Steps
        direction="vertical"
        current={this.state.current}
        onChange={this.changeSurveyStep}
        items={[
          {
            className: this.state.questions.q1.answer !== undefined ? "step-open-by-default" : null,
            title: this.props.gender !== "2" ? this.state.questions.q1.question : this.state.questions.q1.question_female,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q2.question : this.state.questions.q2.question_female,
            className: this.state.questions.q2.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q2 === true &&

                <SurveyTextarea skey={"q2z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q3.question : this.state.questions.q3.question_female,
            className: this.state.questions.q3.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q3"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q4.question : this.state.questions.q4.question_female,
            className: this.state.questions.q4.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q4"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q5.question : this.state.questions.q5.question_female,
            className: this.state.questions.q5.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q5"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q5 === true &&

                  <SurveyTextarea skey={"q5z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q6.question : this.state.questions.q6.question_female,
            className: this.state.questions.q6.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q6"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q7.question : this.state.questions.q7.question_female,
            className: this.state.questions.q7.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q7"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q7 === true &&
                <>
                  <SurveyTextarea skey={"q7z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                  <SurveyTextarea skey={"q7z2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
                </>
              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q8.question : this.state.questions.q8.question_female,
            className: this.state.questions.q8.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q8"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q8 === true &&

                <SurveyTextarea skey={"q8z1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q9.question : this.state.questions.q9.question_female,
            className: this.state.questions.q9.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q9"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q10.question : this.state.questions.q10.question_female,
            className: this.state.questions.q10.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q10"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q10 === true &&

                <SurveyTextarea skey={"q10z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q11.question : this.state.questions.q11.question_female,
            className: this.state.questions.q11.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q11"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q12.question : this.state.questions.q12.question_female,
            className:  this.state.questions.q12.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q12"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q13.question : this.state.questions.q13.question_female,
            className: this.state.questions.q13.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q13"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q14.question : this.state.questions.q14.question_female,
            className: this.state.questions.q14.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q14"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q14 === true &&

                <SurveyTextarea skey={"q14z"} gender={this.props.gender} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q15.question : this.state.questions.q15.question_female,
            className: this.state.questions.q15.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q15"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q16.question : this.state.questions.q16.question_female,
            className: this.state.questions.q16.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q16"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q17.question : this.state.questions.q17.question_female,
            className: this.state.questions.q17.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q17"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q18.question : this.state.questions.q18.question_female,
            className: this.state.questions.q18.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q18"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q19.question : this.state.questions.q19.question_female,
            className: this.state.questions.q19.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q19"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q19 === true &&

                <SurveyTextarea skey={"q19z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q20.question : this.state.questions.q20.question_female,
            className: (this.state.questions.q20a1.answer !== undefined ||
              this.state.questions.q20a2.answer !== undefined ||
              this.state.questions.q20a3.answer !== undefined ||
              this.state.questions.q20a4.answer !== undefined ||
              this.state.questions.q20a5.answer !== undefined ||
              this.state.questions.q20b1.answer !== undefined ||
              this.state.questions.q20c1.answer !== undefined ||
              this.state.questions.q20d1.answer !== undefined ||
              this.state.questions.q20e1.answer !== undefined ||
              this.state.questions.q20f1.answer !== undefined ||
              this.state.questions.q20f2.answer !== undefined ||
              this.state.questions.q20g1.answer !== undefined ||
              this.state.questions.q20h1.answer !== undefined ||
              this.state.questions.q20i1.answer !== undefined ||
              this.state.questions.q20j1.answer !== undefined ||
              this.state.questions.q20k1.answer !== undefined ||
              this.state.questions.q20l1.answer !== undefined ||
              this.state.questions.q20m1.answer !== undefined ||
              this.state.questions.q20n1.answer !== undefined ||
              this.state.questions.q20o1.answer !== undefined
            ) ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <div className="q26-subtitle">a) Mozek nebo nervový systém</div>
              <div className="mi-3">
                <label>{this.state.questions.q20a1.question}</label>
                <YesOrNoRadios skey={"q20a1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q20a2.question}</label>
                <YesOrNoRadios skey={"q20a2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q20a3.question}</label>
                <YesOrNoRadios skey={"q20a3"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q20a4.question}</label>
                <YesOrNoRadios skey={"q20a4"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q20a5.question}</label>
                <YesOrNoRadios skey={"q20a5"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">b) Srdce a krevní oběh</div>
              <div className="mi-3">
                <label>{this.state.questions.q20b1.question}</label>
                <YesOrNoRadios skey={"q20b1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">c) Zhoubné nádory</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q20c1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">d) Dýchací orgány</div>
              <div className="mi-3">
                <label>{this.state.questions.q20d1.question}</label>
                <YesOrNoRadios skey={"q20d1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">e) Zažívací ústrojí</div>
              <div className="mi-3">
                <label>{this.state.questions.q20e1.question}</label>
                <YesOrNoRadios skey={"q20e1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">f) Močové cesty a pohlavní orgány</div>
              <div className="mi-3">
                <label>{this.state.questions.q20f1.question}</label>
                <YesOrNoRadios skey={"q20f1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="mi-3">
                <label>{this.state.questions.q20f2.question}</label>
                <YesOrNoRadios skey={"q20f2"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">g) Onemocnění jater</div>
              <div className="mi-3">
                <label>{this.state.questions.q20g1.question}</label>
                <YesOrNoRadios skey={"q20g1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">h) Malárie nebo jiná tropická onemocnění</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q20h1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">i) Jiná infekční a horečnatá onemocnění (případně kontakt s nimi)</div>
              <div className="mi-3">
                <label>{this.state.questions.q20i1.question}</label>
                <YesOrNoRadios skey={"q20i1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">j) Onemocnění štítné žlázy nebo slinivky břišní</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q20j1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">k) Poruchy krevní srážlivosti (krvácivost nebo trombózy)</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q20k1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">l) Kožní onemocnění</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q20l1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">m) Cukrovka, dna, zvýšený cholesterol</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q20m1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">n) Zánětlivé onemocnění kloubů</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q20n1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div className="q26-subtitle">o) Poruchy imunity (včetně autoimunitních onemocnění)</div>
              <div className="mi-3 mt-2">
                <YesOrNoRadios skey={"q20o1"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>
              </div>
              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q21.question : this.state.questions.q21.question_female,
            className: this.state.questions.q21.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q21"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>


              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          {
            title: this.props.gender !== "2" ? this.state.questions.q22.question : this.state.questions.q22.question_female,
            className: this.state.questions.q22.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q22"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              {this.state.q22 === true &&

                <SurveyTextarea skey={"q22z"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              }

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
          this.props.gender !== "1" && {
            title: this.props.gender !== "2" ? this.state.questions.q23.question : this.state.questions.q23.question_female,
            className: this.state.questions.q23.answer !== undefined ? "step-open-by-default" : null,
            description: <div className="mt-2">
              <YesOrNoRadios skey={"q23"} questions={this.state.questions} setSurveyAnswer={this.setSurveyAnswer}/>

              <div>
                <Button className="btn btn-secondary btn-step" onClick={this.nextStep}>Další</Button>
              </div>
            </div>
          },
        ]}
      />
    </>)
  }
}