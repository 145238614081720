export const AP_SURVEY_QUESTIONS = {
  q1: {
    question: 'Daroval/a jste již někdy plasmu?',
    answer: undefined,
  },
  q1z1: {
    question: 'Kdy naposled?',
    answer: undefined
  },
  q1z2: {
    question: 'Kolikrát?',
    answer: undefined
  },
  q2: {
    question: 'Byl/a jste v posledních 4 týdnech očkován/a včetně orálních očkování, proti dětské obrně?',
    answer: undefined
  },
  q3: {
    question: 'Byl/a jste delší čas v lékařském ošetření?',
    answer: undefined
  },
  q3z1: {
    question: 'Proč?',
    answer: undefined
  },
  q3z2: {
    question: 'Kdy?',
    answer: undefined
  },
  q4: {
    question: 'Prodělal/a jste někdy operace?',
    answer: undefined
  },
  q4z1: {
    question: 'Proč?',
    answer: undefined
  },
  q4z2: {
    question: 'Kdy poslední?',
    answer: undefined
  },
  q5: {
    question: 'Absolvoval/a jste v posledních 7 dnech ošetření u zubního lékaře?',
    answer: undefined
  },
  q6: {
    question: 'Podstoupil/a jste piercing, tetování, permanentní make-up nebo techniky spojené s narušením kůže (skarifikace), náušnice?',
    answer: undefined
  },
  q6z: {
    question: 'Kdy naposledy?',
    answer: undefined
  },
  q7: {
    question: 'Dostal/a jste někdy transfuzi?',
    answer: undefined
  },
  q7z1: {
    question: 'Proč?',
    answer: undefined
  },
  q7z2: {
    question: 'Kdy?',
    answer: undefined
  },
  q8: {
    question: 'Měl/a jste někdy těžký úraz?',
    answer: undefined
  },
  q8z: {
    question: 'Jak dlouho jste byl/a v bezvědomí?',
    answer: undefined
  },
  q9: {
    question: 'Byl Vám zaveden v posledních 6 měsících katetr nebo endoskop?',
    answer: undefined
  },
  q10: {
    question: 'Jste alergický/á?',
    answer: undefined
  },
  q10z: {
    question: 'Na co jste alergický/á?',
    answer: undefined
  },
  q11: {
    question: 'Užíváte trvale léky?',
    answer: undefined
  },
  q11z1: {
    question: 'Které?',
    answer: undefined
  },
  q11z2: {
    question: 'Proč?',
    answer: undefined
  },
  q12: {
    question: 'Užíval/a jste někdy následující léky?',
    answer: undefined
  },
  q12a: {
    question: 'Akné (Accutane, Roaccutan)',
    answer: undefined
  },
  q12b: {
    question: 'Vypadávání vlasů, zvětšení prostaty (Proscar, Propecia)',
    answer: undefined
  },
  q12c: {
    question: 'Lupénka (Tigason, Tegison)',
    answer: undefined
  },
  q12d: {
    question: 'Lék Erivedge',
    answer: undefined
  },
  q13: {
    question: 'Podstoupil/a jste někdy akupunkturu?',
    answer: undefined
  },
  q13z: {
    question: 'Kdy naposled?',
    answer: undefined
  },
  q14: {
    question: 'Pozorujete v poslední době váhový ubytek?',
    answer: undefined
  },
  q15: {
    question: 'Užívala jste někdy humánní hypofízové nebo růstové hormony?',
    answer: undefined
  },
  q16: {
    question: 'Obdržel/a jste transplantát (Transplantát rohovky, tvrdé pleny mozkové)?',
    answer: undefined
  },
  q17: {
    question: 'Zranil/a jste se někdy použitou jehlou nebo nástroji znečištěnými krví?',
    answer: undefined
  },
  q18: {
    question: 'Užíval/a jste někdy nitrožilně drogy?',
    answer: undefined
  },
  q19: {
    question: 'Měl/a jste někdy nechráněný styk s rizikovými osobami (HIV, prostitutka)',
    answer: undefined
  },
  q19z: {
    question: 'Kdy naposled?',
    answer: undefined
  },
  q20: {
    question: 'Máte/Měla jste někdy kontakt jako (nebo s) prostituka(ou)',
    answer: undefined
  },
  q21: {
    question: 'Střídáte často sexuální partnery?',
    answer: undefined
  },
  q22: {
    question: 'Muži: Měl jste někdy pohlavní styk s muži?',
    answer: undefined
  },
  q23: {
    question: 'Byl/a jste v letech 1980-1996 ve Velké Británii nebo podrobil/a jste se tam operaci nebo dostal/a krevní transfuzi?',
    answer: undefined
  },
  q24: {
    question: 'Byl/a jste v posledních 6 měsících mimo území ČR?',
    answer: undefined
  },
  q24z: {
    question: 'Kde?',
    answer: undefined
  },
  q25: {
    question: 'Byl/a jste v posledních 6 měsících ve vazbě?',
    answer: undefined
  },
  q26: {
    question: 'Trpěl/a jste nebo trpíte následujícími poruchami nebo obtížemi?',
    answer: undefined
  },
  q26a: {
    question: 'Ztráta vědomí, meningitis, příznaky ochrnutí',
    answer: undefined
  },
  q26b: {
    question: 'Epileptické záchvaty',
    answer: undefined
  },
  q26c: {
    question: 'Creutzfeld-Jakobova nemoc',
    answer: undefined
  },
  q26d: {
    question: 'Creutzfeld-Jakobova nemoc ve Vaší rodině',
    answer: undefined
  },
  q26e: {
    question: 'Psychiatrícké oněmocnění, změny osobnosti',
    answer: undefined
  },
  q26f: {
    question: 'Maniodepresivní či úzkostné stavy, pokus o sebevraždu',
    answer: undefined
  },
  q26g: {
    question: 'Stavy úzkosti',
    answer: undefined
  },
  q26i: {
    question: 'Cévní onemocnění, srdeční vady, embolie, infarkt, vysoký krevní tlak',
    answer: undefined
  },
  q26j: {
    question: 'Zhoubné nádory',
    answer: undefined
  },
  q26k: {
    question: 'Tuberkulosa, astma, zánět pohrudnice, chronický kašel',
    answer: undefined
  },
  q26l: {
    question: 'Tyfus, onemocnění žaludku a střev',
    answer: undefined
  },
  q26m: {
    question: 'Onemocnění močových cest a ledvin',
    answer: undefined
  },
  q26n: {
    question: 'Syfilis, kapavka',
    answer: undefined
  },
  q26o: {
    question: 'Žloutenka nebo jiné onemocnění jater',
    answer: undefined
  },
  q26p: {
    question: 'Malárie nebo jiná tropická onemocnění',
    answer: undefined
  },
  q26q: {
    question: 'Jiné nakažlivé onemocnění',
    answer: undefined
  },
  q26r: {
    question: 'Jiná infekční a horečnatá onemocnění',
    answer: undefined
  },
  q26rz: {
    question: 'Byla infekce covidem 19 ověřena PCR testem?',
    answer: undefined
  },
  q26s: {
    question: 'Toxoplasmosa, infekční mononukleosa, rickettsiosa, bruceloza, borreliosa, babesiosa, SARS, parovirus B19',
    answer: undefined
  },
  q26t: {
    question: 'Onemocnění štítné žlázy nebo slinivky břišní',
    answer: undefined
  },
  q26u: {
    question: 'Časté krvácení z dásní a nosu, podlitiny, cévní trombózy',
    answer: undefined
  },
  q26v: {
    question: 'Poruchy krvetvorby',
    answer: undefined
  },
  q26w: {
    question: 'Kožní onemocnění',
    answer: undefined
  },
  q26x: {
    question: 'Poruchy látkové výmeny, např. cukrovka',
    answer: undefined
  },
  q26y: {
    question: 'Zánětlivý revmatizmus např. polyartritida nebo jiné autoimunitní onemocnění',
    answer: undefined
  },
  q27: {
    question: 'Užíváte',
    answer: undefined
  },
  q27a: {
    question: 'Alkohol',
    answer: undefined
  },
  q27az: {
    question: 'Jak často užíváte?',
    answer: undefined
  },
  q27b: {
    question: 'Nikotin',
    answer: undefined
  },
  q27bz: {
    question: 'Jak často užíváte?',
    answer: undefined
  },
  q27c: {
    question: 'Jiné drogy',
    answer: undefined
  },
  q27cz: {
    question: 'Jak často užíváte?',
    answer: undefined
  },
  q28: {
    question: 'Strava běžná?',
    answer: undefined
  },
  q28a: {
    question: 'Vegetariánská',
    answer: undefined
  },
  q28b: {
    question: 'Jiná',
    answer: undefined
  },
  q28z: {
    question: 'Jaká?',
    answer: undefined
  },
  q29: {
    question: 'Jiné sdělení lékaři',
    answer: undefined
  },
  q29z: {
    question: 'Jaké?',
    answer: undefined
  },
  q30: {
    question: 'Ženy',
    answer: undefined
  },
  q30a: {
    question: 'Jste těhotná',
    answer: undefined
  },
  q30b: {
    question: 'Porodila jste v posledních 6 měsících?',
    answer: undefined
  },
  q30c: {
    question: 'Kojíte nyní?',
    answer: undefined
  },
  q30d: {
    question: 'Měla jste v posledních 6 měsících potrat?',
    answer: undefined
  }
}

export const AP_SURVEY_QUESTIONS_ANNUAL_CONTROL = {
  q1: {
    question: 'Seznámil jste se s Poučením dárce (Zákonná informační povinnost) a rozumíte mu?',
    question_female: 'Seznámila jste se s Poučením dárce (Zákonná informační povinnost) a rozumíte mu?',
    answer: undefined,
  },
  q2: {
    question: 'Patříte do některé rizikové skupiny uvedené v Poučení dárce?',
    question_female: 'Patříte do některé rizikové skupiny uvedené v Poučení dárce?',
    answer: undefined
  },
  q2z: {
    question: 'Do které?',
    question_female: 'Do které?',
    answer: undefined
  },
  q3: {
    question: 'Cítíte se zdráv?',
    question_female: 'Cítíte se zdráva?',
    answer: undefined
  },
  q4: {
    question: 'Jste v pracovní neschopnosti nebo pobíráte invalidní důchod?',
    question_female: 'Jste v pracovní neschopnosti nebo pobíráte invalidní důchod?',
    answer: undefined
  },
  q5: {
    question: 'Užíváte léky včetně hormonální léčby?',
    question_female: 'Užíváte léky včetně hormonální léčby?',
    answer: undefined
  },
  q5z: {
    question: 'Jaké?',
    question_female: 'Jaké?',
    answer: undefined
  },
  q6: {
    question: 'Byl jste v posledních 4 týdnech očkován?',
    question_female: 'Byla jste v posledních 4 týdnech očkována?',
    answer: undefined
  },
  q7: {
    question: 'Léčil jste se nebo léčíte se u praktického, odborného či zubního lékaře?',
    question_female: 'Léčila jste se nebo léčíte se u praktického, odborného či zubního lékaře?',
    answer: undefined
  },
  q7z1: {
    question: 'S čím?',
    question_female: 'S čím?',
    answer: undefined
  },
  q7z2: {
    question: 'Kdy?',
    question_female: 'Kdy?',
    answer: undefined
  },
  q8: {
    question: 'Prodělal jste nějaké operace?',
    question_female: 'Prodělala jste nějaké operace?',
    answer: undefined
  },
  q8z1: {
    question: 'Jaké a kdy?',
    question_female: 'Jaké a kdy?',
    answer: undefined
  },
  q9: {
    question: 'Měl jste klíště v průběhu posledního měsíce?',
    question_female: 'Měla jste klíště v průběhu posledního měsíce?',
    answer: undefined
  },
  q10: {
    question: 'Měl jste nebo máte piercing či náušnice, tetování/odstranění tetování, permanentní make-up,  akupunkturu nebo podobné techniky spojené s narušením kůže?',
    question_female: 'Měla jste nebo máte piercing či náušnice, tetování/odstranění tetování, permanentní make-up,  akupunkturu nebo podobné techniky spojené s narušením kůže?',
    answer: undefined
  },
  q10z: {
    question: 'Datum posledního zákroku',
    question_female: 'Datum posledního zákroku',
    answer: undefined
  },
  q11: {
    question: 'Dostal jste v posledních 4 měsících krevní transfuzi?',
    question_female: 'Dostala jste v posledních 4 měsících krevní transfuzi? ',
    answer: undefined
  },
  q12: {
    question: 'Měl jste těžký úraz nebo úraz hlavy s bezvědomím?',
    question_female: 'Měla jste těžký úraz nebo úraz hlavy s bezvědomím?',
    answer: undefined
  },
  q13: {
    question: 'Byl jste v posledních 4 měsících na endoskopickém vyšetření?',
    question_female: 'Byla jste v posledních 4 měsících na endoskopickém vyšetření?',
    answer: undefined
  },
  q14: {
    question: 'Máte alergii?',
    question_female: 'Máte alergii?',
    answer: undefined
  },
  q14z: {
    question: 'Na co jste alergický?',
    question_female: 'Na co jste alergická?',
    answer: undefined
  },
  q15: {
    question: 'Pozoroval jste u sebe v poslední době neúmyslný váhový úbytek, zduření lymfatických uzlin nebo noční pocení?',
    question_female: 'Pozorovala jste u sebe v poslední době neúmyslný váhový úbytek, zduření lymfatických uzlin nebo noční pocení?',
    answer: undefined
  },
  q16: {
    question: 'Užíval jste růstové hormony?',
    question_female: 'Užívala jste růstové hormony?',
    answer: undefined
  },
  q17: {
    question: 'Podstoupil jste transplantaci (včetně rohovky, tvrdé pleny mozkové nebo ušního bubínku)?',
    question_female: 'Podstoupila jste transplantaci (včetně rohovky, tvrdé pleny mozkové nebo ušního bubínku)?',
    answer: undefined
  },
  q18: {
    question: 'Užíval jste nitrožilně nebo intramuskulárně (aplikací do svalu) drogy?',
    question_female: 'Užívala jste nitrožilně nebo intramuskulárně (aplikací do svalu) drogy?',
    answer: undefined
  },
  q19: {
    question: 'Byl jste v posledním měsíci mimo území ČR déle než 1 den?',
    question_female: 'Byla jste v posledním měsíci mimo území ČR déle než 1 den?',
    answer: undefined
  },
  q19z: {
    question: 'Pokud ano, kde?',
    question_female: ' Pokud ano, kde?',
    answer: undefined
  },
  q20: {
    question: 'Prodělal jste nebo máte některé z níže vyjmenovaných onemocnění?',
    question_female: 'Prodělala jste nebo máte některé z níže vyjmenovaných onemocnění?',
    answer: null
  },
  q20a1: {
    question: 'Ztráta vědomí, meningitida, encefalitida, příznaky ochrnutí',
    question_female: 'Ztráta vědomí, meningitida, encefalitida, příznaky ochrnutí',
    answer: undefined
  },
  q20a2: {
    question: 'Epileptické záchvaty',
    question_female: 'Epileptické záchvaty',
    answer: undefined
  },
  q20a3: {
    question: 'Creutzfeldt-Jakobova nemoc u vás nebo ve vaší rodině',
    question_female: 'Creutzfeldt-Jakobova nemoc u vás nebo ve vaší rodině',
    answer: undefined
  },
  q20a4: {
    question: 'Psychiatrické onemocnění',
    question_female: 'Psychiatrické onemocnění',
    answer: undefined
  },
  q20a5: {
    question: 'Úzkostné stavy, deprese, pokus o sebevraždu',
    question_female: 'Úzkostné stavy, deprese, pokus o sebevraždu',
    answer: undefined
  },
  q20b1: {
    question: 'Cévní onemocnění, srdeční vady, embolie, infarkt, kolapsové stavy, arytmie, vysoký krevní tlak',
    question_female: 'Cévní onemocnění, srdeční vady, embolie, infarkt, kolapsové stavy, arytmie, vysoký krevní tlak',
    answer: undefined
  },
  q20c1: {
    question: 'Zhoubné nádory',
    question_female: 'Zhoubné nádory',
    answer: undefined
  },
  q20d1: {
    question: 'Tuberkulóza, astma, zánět pohrudnice, chronický kašel',
    question_female: 'Tuberkulóza, astma, zánět pohrudnice, chronický kašel',
    answer: undefined
  },
  q20e1: {
    question: 'Tyfus, onemocnění žaludku a střev',
    question_female: 'Tyfus, onemocnění žaludku a střev',
    answer: undefined
  },
  q20f1: {
    question: 'Onemocnění močových cest a ledvin',
    question_female: 'Onemocnění močových cest a ledvin',
    answer: undefined
  },
  q20f2: {
    question: 'Syfilis, kapavka',
    question_female: 'Syfilis, kapavka',
    answer: undefined
  },
  q20g1: {
    question: 'Žloutenka nebo jiné onemocnění jater',
    question_female: 'Žloutenka nebo jiné onemocnění jater',
    answer: undefined
  },
  q20h1: {
    question: 'Malárie nebo jiná tropická onemocnění',
    question_female: 'Malárie nebo jiná tropická onemocnění',
    answer: undefined
  },
  q20i1: {
    question: 'Toxoplazmóza, infekční mononukleóza, rickettsióza, brucelóza, lymeská borelióza, babesióza, SARS (syndrom akutního respiračního selhání)',
    question_female: 'Toxoplazmóza, infekční mononukleóza, rickettsióza, brucelóza, lymeská borelióza, babesióza, SARS (syndrom akutního respiračního selhání)',
    answer: undefined
  },
  q20j1: {
    question: 'Onemocnění štítné žlázy nebo slinivky břišní',
    question_female: 'Onemocnění štítné žlázy nebo slinivky břišní',
    answer: undefined
  },
  q20k1: {
    question: 'Poruchy krevní srážlivosti (krvácivost nebo trombózy)',
    question_female: 'Poruchy krevní srážlivosti (krvácivost nebo trombózy)',
    answer: undefined
  },
  q20l1: {
    question: 'Kožní onemocnění',
    question_female: 'Kožní onemocnění',
    answer: undefined
  },
  q20m1: {
    question: 'Cukrovka, dna, zvýšený cholesterol',
    question_female: 'Cukrovka, dna, zvýšený cholesterol',
    answer: undefined
  },
  q20n1: {
    question: 'Zánětlivé onemocnění kloubů',
    question_female: 'Zánětlivé onemocnění kloubů',
    answer: undefined
  },
  q20o1: {
    question: 'Poruchy imunity (včetně autoimunitních onemocnění)',
    question_female: 'Poruchy imunity (včetně autoimunitních onemocnění)',
    answer: undefined
  },
  q21: {
    question: 'Držíte nějakou dietu?',
    question_female: 'Držíte nějakou dietu?',
    answer: undefined
  },
  q22: {
    question: 'Jiné sdělení lékaři',
    question_female: 'Jiné sdělení lékaři',
    answer: undefined
  },
  q22z: {
    question: 'Jaké?',
    question_female: 'Jaké?',
    answer: undefined
  },
  q23: {
    question: 'Jste těhotná nebo jste porodila/potratila v posledních 6 měsících? Kojíte?',
    question_female: 'Jste těhotná nebo jste porodila/potratila v posledních 6 měsících? Kojíte?',
    answer: undefined
  },
}

export const AP_SURVEY_QUESTIONS_REGISTRATION = {
  q1: {
    question: 'Seznámil jste se s Poučením dárce (Zákonná informační povinnost) a rozumíte mu?',
    question_female: 'Seznámila jste se s Poučením dárce (Zákonná informační povinnost) a rozumíte mu?',
    answer: undefined,
  },
  q2: {
    question: 'Patříte do některé rizikové skupiny uvedené v Poučení dárce?',
    question_female: 'Patříte do některé rizikové skupiny uvedené v Poučení dárce?',
    answer: undefined
  },
  q2z: {
    question: 'Do které?',
    question_female: 'Do které?',
    answer: undefined
  },
  q3: {
    question: 'Cítíte se zdráv?',
    question_female: 'Cítíte se zdráva?',
    answer: undefined
  },
  q4: {
    question: 'Jste v pracovní neschopnosti nebo pobíráte invalidní důchod?',
    question_female: 'Jste v pracovní neschopnosti nebo pobíráte invalidní důchod?',
    answer: undefined
  },
  q5: {
    question: 'Užíváte léky včetně hormonální léčby?',
    question_female: 'Užíváte léky včetně hormonální léčby?',
    answer: undefined
  },
  q5z: {
    question: 'Jaké?',
    question_female: 'Jaké?',
    answer: undefined
  },
  q6: {
    question: 'Užíváte/užíval jste někdy léky',
    question_female: 'Užíváte/užívala jste někdy léky',
    answer: null
  },
  q6z1: {
    question: 'k léčbě lupénky, akné:',
    question_female: 'k léčbě lupénky, akné',
    answer: undefined
  },
  q6z2: {
    question: 'k léčbě bazaliomu:',
    question_female: 'k léčbě bazaliomu:',
    answer: undefined
  },
  q6z3: {
    question: 'k léčbě hyperplázie prostaty:',
    question_female: 'k léčbě hyperplázie prostaty:',
    answer: undefined
  },
  q7: {
    question: 'Daroval jste již někdy plazmu?',
    question_female: 'Darovala jste již někdy plazmu?',
    answer: undefined
  },
  q7z1: {
    question: 'Kdy naposledy?',
    question_female: 'Kdy naposledy?',
    answer: undefined
  },
  q7z2: {
    question: 'Kolikrát?',
    question_female: 'Kolikrát?',
    answer: undefined
  },
  q8: {
    question: 'Byl jste v posledních 4 týdnech očkován?',
    question_female: 'Byla jste v posledních 4 týdnech očkována?',
    answer: undefined
  },
  q9: {
    question: 'Léčil jste se nebo léčíte se u praktického, odborného či zubního lékaře?',
    question_female: 'Léčila jste se nebo léčíte se u praktického, odborného či zubního lékaře? ',
    answer: undefined
  },
  q9z1: {
    question: 'S čím?',
    question_female: 'S čím?',
    answer: undefined
  },
  q9z2: {
    question: 'Kdy?',
    question_female: 'Kdy?',
    answer: undefined
  },
  q10: {
    question: 'Prodělal jste nějaké operace?',
    question_female: 'Prodělala jste nějaké operace?',
    answer: undefined
  },
  q10z: {
    question: 'Jaké a kdy?',
    question_female: 'Jaké a kdy?',
    answer: undefined
  },
  q11: {
    question: 'Měl jste klíště v průběhu posledního měsíce?',
    question_female: 'Měla jste klíště v průběhu posledního měsíce?',
    answer: undefined
  },
  q12: {
    question: 'Měl jste nebo máte piercing či náušnice, tetování/odstranění tetování, permanentní make-up, akupunkturu nebo podobné techniky spojené s narušením kůže?',
    question_female: 'Měla jste nebo máte piercing či náušnice, tetování/odstranění tetování, permanentní make-up, akupunkturu nebo podobné techniky spojené s narušením kůže?',
    answer: undefined
  },
  q12z: {
    question: 'Datum posledního zákroku',
    question_female: 'Datum posledního zákroku',
    answer: undefined
  },
  q13: {
    question: 'Dostal jste v posledních 4 měsících krevní transfuzi?',
    question_female: 'Dostala jste v posledních 4 měsících krevní transfuzi?',
    answer: undefined
  },
  q14: {
    question: 'Měl jste někdy těžký úraz nebo úraz hlavy s bezvědomím?',
    question_female: 'Měla jste někdy těžký úraz nebo úraz hlavy s bezvědomím?',
    answer: undefined
  },
  q15: {
    question: 'Byl jste v posledních 4 měsících na endoskopickém vyšetření?',
    question_female: 'Byla jste v posledních 4 měsících na endoskopickém vyšetření?',
    answer: undefined
  },
  q16: {
    question: 'Máte alergii?',
    question_female: 'Máte alergii?',
    answer: undefined
  },
  q16z: {
    question: 'Na co jste alergický?',
    question_female: 'Na co jste alergická?',
    answer: undefined
  },
  q17: {
    question: 'Pozoroval jste u sebe v poslední době neúmyslný váhový úbytek, zduření lymfatických uzlin nebo noční pocení?',
    question_female: ' Pozorovala jste u sebe v poslední době neúmyslný váhový úbytek, zduření lymfatických uzlin nebo noční pocení?',
    answer: undefined
  },
  q18: {
    question: 'Užíval jste někdy růstové hormony?',
    question_female: 'Užívala jste někdy růstové hormony?',
    answer: undefined
  },
  q19: {
    question: 'Podstoupil jste někdy transplantaci (včetně rohovky, tvrdé pleny mozkové nebo ušního bubínku)?',
    question_female: 'Podstoupila jste někdy transplantaci (včetně rohovky, tvrdé pleny mozkové nebo ušního bubínku)?',
    answer: undefined
  },
  q20: {
    question: 'Užíval jste někdy nitrožilně nebo intramuskulárně (aplikací do svalu) drogy?',
    question_female: 'Užívala jste někdy nitrožilně nebo intramuskulárně (aplikací do svalu) drogy?',
    answer: undefined
  },
  q21: {
    question: 'Byl jste v letech 1980-1996 déle než 12 měsíců ve Velké Británii?',
    question_female: 'Byla jste v letech 1980-1996 déle než 12 měsíců ve Velké Británii?',
    answer: undefined
  },
  q22: {
    question: 'Dostal jste před rokem 1996 krevní transfuzi v zahraničí?',
    question_female: 'Dostala jste před rokem 1996 krevní transfuzi v zahraničí?',
    answer: undefined
  },
  q23: {
    question: 'Byl jste v posledním měsíci mimo území ČR déle než 1 den?',
    question_female: 'Byla jste v posledním měsíci mimo území ČR déle než 1 den?',
    answer: undefined
  },
  q23z: {
    question: 'Pokud ano, kde?',
    question_female: 'Pokud ano, kde?',
    answer: undefined
  },
  q24: {
    question: 'Byl jste v posledních 4 měsících ve vazbě nebo ve vězení?',
    question_female: 'Byla jste v posledních 4 měsících ve vazbě nebo ve vězení?',
    answer: undefined
  },
  q25: {
    question: 'Prodělal jste nebo máte některé z níže vyjmenovaných onemocnění?',
    question_female: 'Prodělala jste nebo máte některé z níže vyjmenovaných onemocnění?',
    answer: null
  },
  q25a1: {
    question: 'Ztráta vědomí, meningitida, encefalitida, příznaky ochrnutí',
    question_female: 'Ztráta vědomí, meningitida, encefalitida, příznaky ochrnutí',
    answer: undefined
  },
  q25a2: {
    question: 'Epileptické záchvaty',
    question_female: 'Epileptické záchvaty',
    answer: undefined
  },
  q25a3: {
    question: 'Creutzfeldt-Jakobova nemoc u vás nebo ve vaší rodině',
    question_female: 'Creutzfeldt-Jakobova nemoc u vás nebo ve vaší rodině',
    answer: undefined
  },
  q25a4: {
    question: 'Psychiatrické onemocnění',
    question_female: 'Psychiatrické onemocnění',
    answer: undefined
  },
  q25a5: {
    question: 'Úzkostné stavy, deprese, pokus o sebevraždu',
    question_female: 'Úzkostné stavy, deprese, pokus o sebevraždu',
    answer: undefined
  },
  q25b1: {
    question: 'Cévní onemocnění, srdeční vady, embolie, infarkt, kolapsové stavy, arytmie, vysoký krevní tlak',
    question_female: 'Cévní onemocnění, srdeční vady, embolie, infarkt, kolapsové stavy, arytmie, vysoký krevní tlak',
    answer: undefined
  },
  q25c1: {
    question: 'Zhoubné nádory',
    question_female: 'Zhoubné nádory',
    answer: undefined
  },
  q25d1: {
    question: 'Tuberkulóza, astma, zánět pohrudnice, chronický kašel',
    question_female: 'Tuberkulóza, astma, zánět pohrudnice, chronický kašel',
    answer: undefined
  },
  q25e1: {
    question: 'Tyfus, onemocnění žaludku a střev',
    question_female: 'Tyfus, onemocnění žaludku a střev',
    answer: undefined
  },
  q25f1: {
    question: 'Onemocnění močových cest a ledvin',
    question_female: 'Onemocnění močových cest a ledvin',
    answer: undefined
  },
  q25f2: {
    question: 'Syfilis, kapavka',
    question_female: 'Syfilis, kapavka',
    answer: undefined
  },
  q25g1: {
    question: 'Žloutenka nebo jiné onemocnění jater',
    question_female: 'Žloutenka nebo jiné onemocnění jater',
    answer: undefined
  },
  q25h1: {
    question: 'Malárie nebo jiná tropická onemocnění',
    question_female: 'Malárie nebo jiná tropická onemocnění',
    answer: undefined
  },
  q25i1: {
    question: 'Toxoplazmóza, infekční mononukleóza, rickettsióza, brucelóza, lymeská borelióza, babesióza, SARS (syndrom akutního respiračního selhání)',
    question_female: 'Toxoplazmóza, infekční mononukleóza, rickettsióza, brucelóza, lymeská borelióza, babesióza, SARS (syndrom akutního respiračního selhání)',
    answer: undefined
  },
  q25j1: {
    question: 'Onemocnění štítné žlázy nebo slinivky břišní',
    question_female: 'Onemocnění štítné žlázy nebo slinivky břišní',
    answer: undefined
  },
  q25k1: {
    question: 'Poruchy krevní srážlivosti (krvácivost nebo trombózy)',
    question_female: 'Poruchy krevní srážlivosti (krvácivost nebo trombózy)',
    answer: undefined
  },
  q25l1: {
    question: 'Kožní onemocnění',
    question_female: 'Kožní onemocnění',
    answer: undefined
  },
  q25m1: {
    question: 'Cukrovka, dna, zvýšený cholesterol',
    question_female: 'Cukrovka, dna, zvýšený cholesterol',
    answer: undefined
  },
  q25n1: {
    question: 'Zánětlivé onemocnění kloubů',
    question_female: 'Zánětlivé onemocnění kloubů',
    answer: undefined
  },
  q25o1: {
    question: 'Poruchy imunity (včetně autoimunitních onemocnění)',
    question_female: 'Poruchy imunity (včetně autoimunitních onemocnění)',
    answer: undefined
  },
  q26: {
    question: 'Držíte nějakou dietu?',
    question_female: 'Držíte nějakou dietu?',
    answer: undefined
  },
  q27: {
    question: 'Jiné sdělení lékaři',
    question_female: 'Jiné sdělení lékaři',
    answer: undefined
  },
  q27z: {
    question: 'Jaké?',
    question_female: 'Jaké?',
    answer: undefined
  },
  q28: {
    question: 'Jste těhotná nebo jste porodila/potratila v posledních 6 měsících? Kojíte?',
    question_female: 'Jste těhotná nebo jste porodila/potratila v posledních 6 měsících? Kojíte?',
    answer: undefined
  },
}

export const AP_SURVEY_QUESTIONS_GUIDE = {
  q1: {
    question: 'Cítíte se zdráv?',
    question_female: 'Cítíte se zdráva?',
    answer: undefined
  },
  q2: {
    question: 'Jste v pracovní neschopnosti nebo pobíráte invalidní důchod?',
    question_female: 'Jste v pracovní neschopnosti nebo pobíráte invalidní důchod?',
    answer: undefined
  },
  q3: {
    question: 'Léčíte se v současné době u praktického lékaře, odborného či zubního lékaře?',
    question_female: 'Léčíte se v současné době u praktického lékaře, odborného či zubního lékaře?',
    answer: undefined
  },
  q4: {
    question: 'Uveďte, jestli jste od posledního odběru/vstupního vyšetření:',
    question_female: 'Uveďte, jestli jste od posledního odběru/vstupního vyšetření:',
    answer: undefined
  },
  q4a: {
    question: 'Onemocněl, užíval léky, prodělal úraz, operaci, endoskopické vyšetření?',
    question_female: 'Onemocněla, užívala léky, prodělala úraz, operaci, endoskopické vyšetření?',
    answer: undefined
  },
  q4b: {
    question: 'Dostal jste transfuzi krve nebo jste byl očkován?',
    question_female: 'Dostala jste transfuzi krve nebo jste byla očkována?',
    answer: undefined
  },
  q4c: {
    question: 'Byl jste v posledních 4 týdnech očkován proti hepatitidě B?',
    question_female: 'Byla jste v posledních 4 týdnech očkována proti hepatitidě B?',
    answer: undefined
  },
  q4d: {
    question: 'Měl jste klíště?',
    question_female: 'Měla jste klíště?',
    answer: undefined
  },
  q4e: {
    question: 'Užíval jste nitrožilně nebo intramuskulárně (aplikací do svalu) drogy?',
    question_female: 'Užívala jste nitrožilně nebo intramuskulárně (aplikací do svalu) drogy?',
    answer: undefined
  },
  q4f: {
    question: 'Používal jste použité injekční jehly anebo nástroje kontaminované krví nebo se jimi zranil?',
    question_female: 'Používala jste použité injekční jehly anebo nástroje kontaminované krví nebo se jimi zranila?',
    answer: undefined
  },
  q4g: {
    question: 'Nechal jste si aplikovat piercing či náušnice, podstoupil jste tetování, permanentní make-up, akupunkturu nebo podobné techniky spojené s narušením kůže?',
    question_female: 'Nechala jste si aplikovat piercing či náušnice, podstoupila jste tetování, permanentní make-up, akupunkturu nebo podobné techniky spojené s narušením kůže?',
    answer: undefined
  },
  q4i: {
    question: 'Byl jste v kontaktu s rizikovými osobami z hlediska nákazy pohlavně přenosnými chorobami (HIV, žloutenky B, C – viz informace o HIV a AIDS)?',
    question_female: 'Byla jste v kontaktu s rizikovými osobami z hlediska nákazy pohlavně přenosnými chorobami (HIV, žloutenky B, C – viz informace o HIV a AIDS)?',
    answer: undefined
  },
  q4j: {
    question: 'Zpozoroval jste u sebe neúmyslný váhový úbytek, zduření lymfatických uzlin nebo noční pocení?',
    question_female: 'Zpozorovala jste u sebe neúmyslný váhový úbytek, zduření lymfatických uzlin nebo noční pocení?',
    answer: undefined
  },
  q4k: {
    question: 'Byl jste v posledním měsíci mimo území ČR déle než 1 den?',
    question_female: 'Byla jste v posledním měsíci mimo území ČR déle než 1 den?',
    answer: undefined
  },
  q4kz: {
    question: 'Pokud ano, kde?',
    question_female: 'Pokud ano, kde?',
    answer: undefined
  },
  q4l: {
    question: 'Byl jste ve vazbě nebo ve vězení?',
    question_female: 'Byla jste ve vazbě nebo ve vězení?',
    answer: undefined
  },
  q5: {
    question: 'Jste dostatečně obeznámen s problematikou nákazy virem HIV a onemocněním AIDS?',
    question_female: 'Jste dostatečně obeznámena s problematikou nákazy virem HIV a onemocněním AIDS?',
    answer: undefined
  },
  q6: {
    question: 'Těhotenství, porod nebo potrat v posledních 6 měsících?',
    question_female: 'Těhotenství, porod nebo potrat v posledních 6 měsících?',
    answer: undefined
  },
}
